<script>
import Layout from "../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import { VueTelInput } from "vue-tel-input";
// import {mapState,mapActions} from "vuex";
import { userMethods } from "@/state/helpers";
import imageUrl from "@/assets/images/dashboard/default-logo.png";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  page: {
    title: "Profile",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    VueUploadMultipleImage,
    VueTelInput,
  },

  data() {
    return {
      restaurant: {
        name: null,
        country: null,
        logo: null,
      },
      fileName: "",
      title: "My Profile",
      form: {
        country: null,
        day: null,
        category: null,
        area: null,
      },
      userImage: [
        {
          path: "",
          default: 1,
          highlight: 1,
        },
      ],
      RestaurantImage: [
        {
          path: "",
          default: 1,
          highlight: 1,
        },
      ],
      processing: false,
      items: [
        {
          text: "My Profile",
        },
        {
          text: "My Profile",
          active: true,
        },
      ],
      step: null,
      myAccountSteps: null,
      old_password: null,
      new_password: null,
      confirm_password: null,
      // default_avatar:null,
      phoneError: true,
      selectAll: false,
      permissions: [],
    };
  },

  validations() {
    if (this.myAccountSteps == 0) {
      return {
        user: {
          name: { required },
          email: { required },
          phone: { required },
        },
      };
    } else if (this.myAccountSteps == 1) {
      return {
        old_password: { required },
        new_password: { required },
        confirm_password: { required },
      };
    } else {
      return {};
    }
  },
  computed: {
    user: {
      get() {
        this.userImage.forEach((item) => {
          if (item.path != null) {
            item.path =
              this.currentUser.avatar == null
                ? null
                : this.assets_url + this.currentUser.avatar;
          } else {
            this.userImage = [];
          }
        });
        if (this.isRestaurantOwner()) {
          this.RestaurantImage.forEach((item) => {
            if (item.path != null) {
              item.path =
                this.currentUser.restaurant.logo == null
                  ? null
                  : this.assets_url + this.currentUser.restaurant.logo;
            } else {
              this.RestaurantImage = [];
            }
          });
        }
        return this.$store.state.userModule.user;
      },
      set(value) {
        this.$store.commit("updateUser", value);
      },
    },
    uniqueGroups() {
      return [
        ...new Set(this.permissions.map((permission) => permission.group)),
      ];
    },
    preferencesByGroup() {
      return (group) =>
        this.permissions.filter((permission) => permission.group === group);
    },
  },
  watch: {
    "user.phone": {
      handler: function (newVal, oldVal) {
        if (newVal == "") {
          this.phoneError = false;
        }
      },
    },
    selectAll(newVal, oldVal) {
      newVal
        ? this.permissions.map((p) => (p.value = "1"))
        : this.permissions.map((p) => (p.value = "0"));
    },
  },
  mounted() {
    // this.default_avatar = this.$store.state.auth.currentUser.avatar_path; //to update default avatar in topbar when user delete existing-one
    let self = this;
    self.$axios
      .get("/settings")
      .then((response) => {
        self.permissions = response.data.payload;
      })
      .catch((error) => {
        this.handleErrorResponse(error.response, "error");
      });
  },
  created() {
    this.getuser();
    this.imageUrl = imageUrl;
  },

  methods: {
    ...userMethods,

    uploadImageSuccess(formData, index, fileList) {
      var self = this;
      self.$axios
        .post("/my-profile-image", formData)
        .then((response) => {
          self.fileName = response.data;
          self.user.avatar = self.fileName;
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        });
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      var r = confirm("remove image");
      if (r == true) {
        done();
        var self = this;
        this.$axios
          .post("/delete-profile-image", { fileName: self.fileName })
          .then((response) => {
            self.fileName = "";
            this.user.avatar = self.fileName;
            if (this.isRestaurantOwner())
              this.user.restaurant.logo = self.fileName;
          })
          .catch((error) => {
            this.handleErrorResponse(error.response, "error");
          })
          .finally(function () {});
      }
    },
    editImage(formData, index, fileList) {
      var self = this;
      if (self.fileName == "") {
        self.fileName = self.user.avatar;
      }
      formData.append("fileName", self.fileName);
      this.$axios
        .post("/update-profile-image", formData)
        .then((response) => {
          self.fileName = response.data;
          self.user.avatar = self.fileName;
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(function () {});
    },
    checkPhone(phone) {
      if (this.user.phone) {
        this.phoneError = phone.valid;
      }
    },
    updateProfile() {
      this.submitted = true;
      let self = this;
      if (self.processing) {
        return;
      }
      if (this.phoneError == false) {
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      self.processing = true;
      self.$axios
        .post("/admin/update/" + this.user.id, this.user)
        .then((response) => {
          let data = response.data;
          self.triggerSwal(data.message, "success");
          // location.reload();
          self.$store.state.auth.currentUser.name = data.payload.name;
          self.$store.state.auth.currentUser.avatar =
            data.payload.avatar == null ? null : data.payload.avatar;
        })
        .catch((error) => {
          self.handleErrorResponse(error.response, "error");
        })
        .finally(() => {
          self.processing = false;
        });
    },

    updateResProfile() {
      let self = this;
      if (self.processing === true) {
        return false;
      }
      self.processing = true;
      this.$axios
        .post(
          "/restaurant/owner/update-restaurant/" + self.user.restaurant.id,
          self.user.restaurant
        )
        .then((response) => {
          if (response.data.status == true) {
            self.triggerSwal(response.data.message, "success");
            self.$store.state.auth.currentUser.restaurant.name =
              response.data.payload.name;
            self.$store.state.auth.currentUser.restaurant.logo =
              response.data.payload.logo == null
                ? null
                : response.data.payload.logo;
          }
          self.processing = false;
        })
        .catch((error) => {
          self.handleErrorResponse(error.response, "error");
        })
        .finally(() => {
          self.processing = false;
        });
    },
    editResImage(formData, index, fileList) {
      // console.log("edit data", formData, index, fileList);
      var self = this;
      // console.log(self.fileName);
      if (self.fileName == "") {
        self.fileName = self.user.restaurant.logo;
      }
      formData.append("fileName", self.fileName);
      this.$axios
        .post("/update-profile-image", formData)
        .then((response) => {
          // console.log(response);

          self.fileName = response.data;
          self.user.restaurant.logo = self.fileName;
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(function () {});
    },
    uploadRestSuccess(formData) {
      var self = this;
      self.$axios
        .post("/my-profile-image", formData)
        .then((response) => {
          self.user.restaurant.logo = response.data;
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(function () {});
    },

    updateNotificationPreferences() {
      let self = this;
      self.processing = true;
      self.$axios
        .post("settings/update", self.permissions)
        .then((response) => {
          self.permissions = response.data.payload;
          self.triggerSwal(response.data.message, "success");
        })
        .catch((error) => {
          console.log(error);
          self.handleErrorResponse(error.response, "error");
        })
        .finally(() => {
          self.processing = false;
        });
    },

    updatePassword() {
      let self = this;
      if (self.new_password != self.confirm_password) {
        self.triggerSwal("Confrim Password Not Match", "error");
        return;
      }
      if (self.processing) {
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      self.processing = true;

      self.$axios
        .post("/user/update-password/" + self.user.id, {
          id: self.user.id,
          new_password: self.new_password,
          old_password: self.old_password,
        })
        .then((response) => {
          let data = response.data;
          self.triggerSwal(data.message, "success");
        })
        .catch((error) => {
          self.handleErrorResponse(error.response, "error");
        })
        .finally(() => {
          self.processing = false;
        });
    },
    rolesToString(areaRoles) {
      let name_array = areaRoles.map((role) => {
        return role.name;
      });
      let string = name_array.join(" , ").toString();

      return string;
    },

    showWorkArea(areaRoles) {
      let areaRolesArray = [];
      areaRoles.forEach((element) => {
        let found = areaRolesArray.findIndex(
          (workAreaName) => workAreaName == element.area.name
        );
        if (found < 0) {
          areaRolesArray.push(element.area.name);
        }
      });
      return areaRolesArray.join(" , ").toString();
    },
    showLocation(locations) {
      let location_array = locations.map((loc) => {
        return loc.name;
      });
      let string = location_array.join(" , ").toString();
      return string;
    },
    convertToTitleCase(str) {
      return str
        .toLowerCase()
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    checkAllPreferences(group, event) {
      event.target.checked
        ? this.preferencesByGroup(group).map(
            (preference) => (preference.value = "1")
          )
        : this.preferencesByGroup(group).map(
            (preference) => (preference.value = "0")
          );
    },
    isGroupChecked(group) {
      const preferences = this.preferencesByGroup(group);
      return preferences.every((preference) => preference.value === "1");
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" />
    <div class="row">
      <div class="mb-0 tabs-pills">
        <b-tabs pills content-class="text-muted " v-model="step">
          <b-tab active>
            <template v-slot:title>
              <span class="">My Account</span>
            </template>

            <div class="mt-3">
              <b-tabs pills content-class="text-muted" v-model="myAccountSteps">
                <b-tab active>
                  <template v-slot:title>
                    <span class="">Profile</span>
                  </template>

                  <!-- *********************************  My Account Start  *********************************** -->
                  <div class="card mt-3" v-if="myAccountSteps == 0">
                    <div class="card-body">
                      <form class="form-horizontal" role="form">
                        <div class="row">
                          <div class="col-md-12 mb-3">
                            <vue-upload-multiple-image
                              @upload-success="uploadImageSuccess"
                              @before-remove="beforeRemove"
                              @edit-image="editImage"
                              :data-images="userImage"
                              idUpload="myIdUpload"
                              dragText=""
                              editUpload="myIdEdit"
                              browseText="Upload Image"
                              :multiple="false"
                              primaryText="Defualt Image"
                              popupText="This image will be displayed as default"
                              :markIsPrimaryText="currentUser.avatar_path"
                            ></vue-upload-multiple-image>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                              class="mb-3"
                              id="example text"
                              label="Name"
                              label-for="text"
                            >
                              <b-form-input
                                for="text"
                                v-model="user.name"
                                :class="{ 'is-invalid': $v.user.name.$error }"
                              ></b-form-input>
                              <div
                                div
                                v-if="$v.user.name.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.user.name.required"
                                  >Name is required.</span
                                >
                              </div>
                            </b-form-group>
                          </div>

                          <div class="col-md-6 mb-3">
                            <b-form-group
                              id="input-group-3"
                              label="Email"
                              label-for="email"
                            >
                              <b-form-input
                                for="email"
                                type="email"
                                v-model="user.email"
                                :class="{ 'is-invalid': $v.user.email.$error }"
                                :disabled="true"
                                required
                              ></b-form-input>
                              <div
                                div
                                v-if="$v.user.email.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.user.email.required"
                                  >Email is required.</span
                                >
                              </div>
                            </b-form-group>
                          </div>
                          <div class="col-md-6 mb-3">
                            <b-form-group
                              id="input-group-3"
                              label="Phone"
                              label-for="input-3"
                            >
                            </b-form-group>
                            <vue-tel-input
                              v-model="user.phone"
                              :autoFormat="true"
                              :dropdownOptions="{
                                showFlags: true,
                                showSearchBox: true,
                              }"
                              :inputOptions="{ type: 'tel' }"
                              :invalidMsg="'Invalid'"
                              :class="{ 'is-invalid': $v.user.phone.$error }"
                              :mode="'international'"
                              @validate="checkPhone($event)"
                              :validCharactersOnly="true"
                            ></vue-tel-input>
                            <div v-if="!phoneError" class="text-danger mt-1">
                              Invalid Phone Number.
                            </div>
                            <div
                              div
                              v-if="$v.user.phone.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.user.phone.required"
                                >Phone is required.</span
                              >
                            </div>
                          </div>
                          <div
                            class="col-md-6 mb-3"
                            v-if="isManager() && user.employee"
                          >
                            <b-form-group
                              id="input-group-3"
                              label="Experience"
                              label-for="input-3"
                            >
                            </b-form-group>
                            <span class="form-control bg-light">{{
                              user.employee.experience_level
                            }}</span>
                          </div>
                          <div v-if="isManager() && user.employee" class="row">
                            <div class="col-md-6 mb-3">
                              <b-form-group
                                id="input-group-3"
                                label="Availability"
                                label-for="input-3"
                              >
                              </b-form-group>
                              <span class="form-control bg-light">{{
                                user.employee.employee_type
                              }}</span>
                            </div>
                            <div class="col-md-6 mb-3">
                              <b-form-group
                                id="input-group-3"
                                label="Location"
                                label-for="input-3"
                              >
                              </b-form-group>
                              <span class="form-control bg-light">{{
                                showLocation(user.employee.locations)
                              }}</span>
                            </div>
                            <div class="col-md-6 mb-3">
                              <b-form-group
                                id="input-group-3"
                                label="Work Area"
                                label-for="input-3"
                              >
                              </b-form-group>
                              <span class="form-control bg-light">{{
                                showWorkArea(user.employee.area_roles)
                              }}</span>
                            </div>
                            <div class="col-md-6 mb-3">
                              <b-form-group
                                id="input-group-3"
                                label="Role"
                                label-for="input-3"
                              >
                              </b-form-group>
                              <span class="form-control bg-light">{{
                                rolesToString(user.employee.area_roles)
                              }}</span>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <SubmitButton
                              @clicked="updateProfile"
                              text="save"
                              :processing="processing"
                            ></SubmitButton>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <!-- *********************************  My Account End    *********************************** -->
                </b-tab>
                <b-tab>
                  <template v-slot:title>
                    <span class="">Change Password</span>
                  </template>
                  <div class="card mt-3" v-if="myAccountSteps == 1">
                    <div class="card-body">
                      <form class="form-horizontal" role="form">
                        <div class="row justify-content-md-center">
                          <div class="col-md-4">
                            <b-form-group
                              id="old_password-group"
                              label="Old Password"
                              class="mb-3"
                              label-for="old_password"
                            >
                              <b-form-input
                                id="old_password"
                                v-model="old_password"
                                :class="{
                                  'is-invalid': $v.old_password.$error,
                                }"
                                type="password"
                                placeholder="Enter password"
                              >
                              </b-form-input>
                              <div
                                v-if="$v.old_password.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.old_password.required"
                                  >Old Password is required.</span
                                >
                              </div>
                            </b-form-group>
                          </div>
                          <div class="col-md-4">
                            <b-form-group
                              id="password-group"
                              label="New Password"
                              class="mb-3"
                              label-for="password"
                            >
                              <b-form-input
                                id="password"
                                v-model="new_password"
                                type="password"
                                :class="{
                                  'is-invalid': $v.new_password.$error,
                                }"
                                placeholder="Enter password"
                              >
                              </b-form-input>
                              <div
                                v-if="$v.new_password.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.new_password.required"
                                  >New Password is required.</span
                                >
                              </div>
                            </b-form-group>
                          </div>
                          <div class="col-md-4">
                            <b-form-group
                              id="confirm_password-group"
                              label="Confirm Password"
                              class="mb-3"
                              label-for="confirm_password"
                            >
                              <b-form-input
                                id="confirm_password"
                                v-model="confirm_password"
                                type="password"
                                :class="{
                                  'is-invalid': $v.confirm_password.$error,
                                }"
                                placeholder="Enter password"
                              >
                              </b-form-input>
                              <div
                                v-if="$v.confirm_password.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.confirm_password.required"
                                  >Confirm Password is required.</span
                                >
                              </div>
                            </b-form-group>
                          </div>
                          <div class="col-md-12">
                            <SubmitButton
                              :processing="processing"
                              @clicked="updatePassword"
                              text="Update Password"
                            >
                            </SubmitButton>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </b-tab>
                <b-tab>
                  <template
                    v-slot:title
                    v-if="
                      currentUser.type == 'restaurant_owner' ||
                      currentUser.type == 'manager'
                    "
                  >
                    <span class="">Notifications Preference</span>
                  </template>
                  <div class="card mt-3">
                    <div class="card-body">
                      <form class="form-horizontal" role="form">
                        <div class="row justify-content-md-center">
                          <div class="col-md-12">
                            <div class="form-group">
                              <input
                                type="checkbox"
                                id="parent-permission"
                                class="form-check-input"
                                v-model="selectAll"
                              />
                              <label for="parent-permission" class="ps-2"
                                >Select All</label
                              >
                            </div>
                          </div>
                          <div class="col-md-4 col-12" v-for="group in uniqueGroups" :key="group">
                            <label>
                              <input
                                type="checkbox"
                                class="form-check-input"
                                :checked="isGroupChecked(group)"
                                @change="checkAllPreferences(group, $event)"
                              />
                              {{ group.toUpperCase() }}
                            </label>
                            <div class="child_checks ps-3">
                              <div
                                v-for="preference in preferencesByGroup(group)"
                                :key="preference.id"
                                class="form-group"
                              >
                                <b-form-checkbox
                                  v-model="preference.value"
                                  value="1"
                                  unchecked-value="0"
                                  class="mb-3"
                                  checked
                                  plain
                                  >{{
                                    convertToTitleCase(preference.key)
                                  }}</b-form-checkbox
                                >
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 text-end">
                            <SubmitButton
                              :processing="processing"
                              @clicked="updateNotificationPreferences"
                              text="Update Preferences"
                            >
                            </SubmitButton>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </b-tab>

          <b-tab v-if="isRestaurantOwner()">
            <template v-slot:title>
              <span class="">Business Info</span>
            </template>
            <div class="card mt-3">
              <div class="card-body">
                <form class="form-horizontal" role="form">
                  <div class="row">
                    <div class="col-md-12 mb-3">
                      <vue-upload-multiple-image
                        @upload-success="uploadRestSuccess"
                        @before-remove="beforeRemove"
                        @edit-image="editResImage"
                        :data-images="RestaurantImage"
                        idUpload="idRes"
                        editUpload="myIdResEdit"
                        browseText="Upload Image"
                        dragText=""
                        :multiple="false"
                        primaryText="Defualt Image"
                        popupText="This image will be displayed as default"
                      ></vue-upload-multiple-image>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        class="mb-3"
                        id="example text"
                        label="Business Name"
                        label-for="text"
                      >
                        <b-form-input
                          for="text"
                          v-model="user.restaurant.name"
                          value="Artisanal kale"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        class="mb-3"
                        id="example text"
                        label="Country"
                        label-for="text"
                      >
                        <b-form-input
                          type="tel"
                          for="text"
                          v-model="user.restaurant.country"
                          placeholder="USA"
                        ></b-form-input>
                      </b-form-group>
                    </div>

                    <div class="col-md-12">
                      <SubmitButton
                        :processing="processing"
                        @clicked="updateResProfile"
                        text="save"
                      ></SubmitButton>
                    </div>
                  </div>
                </form>
              </div>

              <!-- end .border-->
            </div>
          </b-tab>
        </b-tabs>
        <!-- Nav tabs -->
        <!-- Tab content -->
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.nav-tabs {
  border-bottom: 0px !important;
}
</style>